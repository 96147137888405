<template>
  <div class="info-container">
    <!-- <NavBack :navBackMsg="navBackMsg"></NavBack> -->
    <!-- 覆盖最上方静止选择和修改数据 -->
    <div class="disabled-box" v-if="!saveShow"></div>
    <div class="info-cen">
      <div class="title">
        <div class="title-l">
          <svg class="closeImg" aria-hidden="true">
            <use :xlink:href="'#icon-yinhangkaxinxi'" />
          </svg>
          银行卡信息
        </div>
        <div class="title-r">
          <!-- <van-button
            icon="add-o"
            round
            type="info"
            @click="addItem"
            v-if="menuLayout !== '1'"
            >添加</van-button
          > -->
        </div>
      </div>
      <div
        class="info-main"
        v-for="(parent, index) in fromData"
        :key="index"
        style="margin-bottom: 15px"
      >
        <van-cell-group>
          <van-cell v-for="(item, index2) in parent" :key="index2">
            <!-- <OcrIdentityView
              v-if="item.type === 'uploadImg'"
              :value.sync="item.value"
            >
            </OcrIdentityView> -->
            <van-field
              v-if="item.type === 'text'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              :disabled="item.bankCardOcr"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <!-- <van-field
              v-if="item.type === 'text' && item.label === '开户银行账号'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
            >
              <van-icon
                class="iconfont"
                :class="
                  cardCode ? 'icon-zizhuruzhi-miwen' : 'icon-zizhuruzhi-mingwen'
                "
                class-prefix="icon"
                slot="right-icon"
                name="custom"
                @click="iconClick(item)"
              ></van-icon>
            </van-field> -->

            <van-field
              v-if="item.type === 'textArea'"
              v-model="item.value"
              :label="item.label"
              rows="1"
              type="textarea"
              :required="item.required"
              :autosize="item.autosize"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <SelectView
              v-if="item.type === 'select'"
              :value.sync="item.value"
              :list="item.list"
              :label="item.label"
              :required="item.required"
            ></SelectView>
            <DatetimeView
              v-if="item.type === 'date'"
              :value.sync="item.value"
              :type="item.type"
              :label="item.label"
              :required="item.required"
            ></DatetimeView>
            <AreaView
              v-if="item.type === 'cascader'"
              :value.sync="item.value"
              :label="item.label"
              :required="item.required"
            ></AreaView>
            <SwitchView
              v-if="item.type === 'boolean'"
              :value.sync="item.value"
              :label="item.label"
              :required="item.required"
              @switchValChange="switchValChange(item)"
            ></SwitchView>
            <BankView
              v-if="item.type === 'uploadImg'"
              :value.sync="item.value"
              :importType.sync="item.fieldCode"
              :disabled="!saveShow"
            >
            </BankView>
            <van-field
              v-if="item.type === 'percentage'"
              type="digit"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              :disabled="item.bankCardOcr"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
              readonly
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              >
              <div class="percentSty">%</div>
              </van-icon>
            </van-field>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="footer">
      <van-button type="info" @click="save" v-if="saveShow" v-btnClick
        >保存</van-button
      >
    </div>
  </div>
</template>
<script>
import SelectView from "../../components/select-view/index"; //下拉选择
import DatetimeView from "../../components/Datetime-view/index"; //日期类型选择
import OcrIdentityView from "../../components/ocr/ocr-identity-view"; //身份证OCR
import AreaView from "../../components/Area-view/index";
import NavBack from "../../components/nav-back/index";
import SwitchView from "../../components/switch-view/index"; //滑块
import UploadImgView from "../../components/upload-img-view/index"; //上传证书
import BankView from "../../components/ocr/ocr-bank-view";
import utils from "@/utils/index";
import { mapGetters } from "vuex";
import API from "../../api/http";
import { Toast } from "vant";
/**
  type 类型：
     input：普通input框,右边无icon
    input2: 右边icon有点击事件的input框
    select：下拉
    date：日期
    area：省市区
    textarea：多列展示
    identity：身份证
 */
export default {
  components: {
    SelectView,
    DatetimeView,
    AreaView,
    OcrIdentityView,
    NavBack,
    SwitchView,
    UploadImgView,
    BankView,
  },
  data() {
    return {
      navBackMsg: {
        title: "银行卡信息", // 导航文字内容
        backBtn: true,
      },
      fromData: [],
      cardCode: false,
      menuLayout: "1",
      timerNum: 0,
      approvalStatus: "",
      bankCardData: {
        bank_account: true,
        bank_name: true,
      },
      bankObj: {}, //ocr识别出的信息
      bankImg: {}, //当前上传的图片值
    };
  },
  created() {
    this.templateMenuId = this.$route.query.id;
    this.menuLayout = this.$route.query.menuLayout;
    this.approvalStatus = Number(this.$route.query.astatus);
    this.getInit();
  },
  mounted() {},
  computed: {
    ...mapGetters("user", {
      clientCode: "clientCode",
      temporaryCode: "temporaryCode",
      mcode: "mcode",
    }),
    saveShow() {
      if (this.mcode) {
        //人员状态未10、20,可以再次保存
        if (this.approvalStatus === 10 || this.approvalStatus === 20) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  watch: {
    fromData: {
      handler(val) {
        this.fromWatch();
      },
      deep: true,
    },
  },
  methods: {
    getInit() {
      API.getFieldByMenu({
        templateMenuId: this.templateMenuId,
        clientCode: this.clientCode,
        temporaryCode: this.temporaryCode,
        mcode: this.mcode,
      }).then((res) => {
        let result = res.data;
        this.fromData = this.setList(result);
        //如果基本信息填了名字，并且这里是空的付值
        this.fromData[0].forEach((item) => {
          if (item.fieldCode === "bank_percentage"){
            item.value = 100
          }
          if (item.fieldCode === "payee_name" && item.value === "") {
            if (
              item.clientTemplateCheckField !== null &&
              item.clientTemplateCheckField.checkFieldRegexp
            ) {
              let reg = item.clientTemplateCheckField.checkFieldRegexp;
              let val = reg.match(/\(([^)]*)\)/)[1];
              this.$set(item, "value", val);
            }
          }
        });
      });
    },
    addItem() {
      //添加
      if (
        this.menuLayout !== "N" &&
        Number(this.menuLayout) === this.fromData.length
      ) {
        Toast({
          message: `最多添加${this.menuLayout}个${this.navBackMsg.title}`,
          className: "totast-cum",
        });
        return;
      }
      let arr = JSON.parse(JSON.stringify(this.fromData[0]));
      arr.forEach((item) => {
        if (item.type === "boolean") {
          item.value = false;
        } else if (item.type === "uploadImg") {
          item.list = [];
          item.value = "";
        } else {
          item.value = "";
        }
      });
      this.fromData.push(arr);
    },
    setFromData() {
      //将省份证号码转为 密文显示
      this.fromData.map((item) => {
        if (item.type === "input2") {
          this.cardCodeValue = JSON.parse(JSON.stringify(item.value));
          item.value = utils.setIdCard(item.value);
        }
      });
    },
    switchValChange(item) {
      //只能设置一个紧急联系人
      if (item.value) {
        let num = 0;
        this.fromData.forEach((parentItem) => {
          parentItem.forEach((childItem) => {
            if (
              childItem.value &&
              childItem.type === "boolean" &&
              childItem.label === "最高学历"
            ) {
              num++;
            }
          });
        });
        if (num > 1) {
          Toast({
            message: "最高学历只能设置一个",
          });
          setTimeout(() => {
            this.$set(item, "value", false);
          }, 200);
        }
      }
    },
    setList(arrList) {
      let newArr = [];
      arrList.forEach((arr, index) => {
        let menuRelList1 = arr.filter(
          (item) => item.propertyType === "uploadImg"
        );
        let menuRelList2 = arr.filter(
          (item) => item.propertyType !== "uploadImg"
        );
        let arr1 = menuRelList1.map((child) => {
          // let val = JSON.parse(child.fieldValue || '[]')
          let val = child.fieldValue;
          this.bankImg[index] = val;
          return {
            label: child.fieldDesc,
            value: val,
            fieldCode: child.fieldCode,
            type: child.propertyType,
            tipsDesc: child.tipsDesc,
            required: child.isNotNull === 1 ? true : false,
            clientTemplateCheckField: child.clientTemplateCheckField,
            bankCardOcr: child.bankCardOcr === 1 ? true : false,
            // list: child.defaultValue === '' ? [] : [{url:child.defaultValue}]
          };
        });

        let data = menuRelList2.map((child) => {
          let value = child.fieldValue;
          if (child.propertyType === "boolean") {
            value = JSON.parse(value || "false");
          }
          return {
            label: child.fieldDesc, //字段名
            value: value, //字段值
            fieldCode: child.fieldCode, //字段code
            type: child.propertyType, //字段类型
            tipsDesc: child.tipsDesc, //字段提示
            required: child.isNotNull === 1 ? true : false,
            bankCardOcr: false,
            // bankCardOcr:
            //   this.bankCardData[child.fieldCode] && child.bankCardOcr === 1,
            propertyLength:
              child.propertyLength &&
              child.propertyLength !== 0 &&
              child.propertyLength !== ""
                ? child.propertyLength
                : 200,
            clientTemplateCheckField: child.clientTemplateCheckField,
            list: child.downList.map((dom) => {
              return {
                value: dom.dataCode,
                text: dom.dataValue,
              };
            }),
          };
        });
        if (arr1.length > 0) {
          // data.push({
          //   label: "",
          //   type: "uploadImg",
          //   value: [...arr1],
          // });
          data.unshift(...arr1);
        }
        newArr.push(data);
      });
      return newArr;
    },
    save() {
      let flag = true;
      for (let parentsItem of this.fromData) {
        for (let item of parentsItem) {
          if (item.type !== "boolean" && item.required && !item.value) {
            let msg = item.label + "不能为空";
            if (item.tipsDesc) {
              msg = item.tipsDesc + item.label;
            }
            Toast({
              message: msg,
              className: "totast-cum",
            });
            flag = false;
            break;
          } else if (
            item.value !== "" &&
            item.clientTemplateCheckField !== null
          ) {
            //校验输入的值正则表达式
            if (
              item.clientTemplateCheckField.checkFieldRegexp !== "" &&
              item.clientTemplateCheckField.checkFieldType === "warning"
            ) {
              //校验开户名字和基本信息名字是否相同
              let reg = item.clientTemplateCheckField.checkFieldRegexp;
              let val = reg.match(/\(([^)]*)\)/)[1];
              if (val !== item.value) {
                Toast({
                  message: `开户名与身份信息的姓名不一致`,
                });
                this.timerNum = 2000;
              } else {
                this.timerNum = 0;
              }
            } else {
              let reg = eval(item.clientTemplateCheckField.checkFieldRegexp);
              if (!reg.test(item.value)) {
                Toast({
                  message: `${item.tipsDesc}正确的${item.label}`,
                });
                flag = false;
                break;
              }
            }
          }
        }
      }
      if (flag) {
        //成功
        setTimeout(() => {
          let list = this.setFroms();
          API.addBasicFieldVale({
            templateMenuId: this.templateMenuId,
            temporaryCode: this.temporaryCode,
            menuFieldRelList: list,
            clientCode: this.clientCode,
            mcode: this.mcode,
          }).then((res) => {
            Toast({
              message: res.message,
              className: "toast-yellow",
            });
            this.saveBack();
          });
        }, this.timerNum);
      }
    },
    saveBack() {
      setTimeout(() => {
        this.$router.back(-1);
        // this.$router.push({ path: "/user", query: { ...this.$route.query } });
      }, 1000);
    },
    setFroms() {
      let menuFieldRelList = this.fromData.map((item) => {
        item = item.map((element) => {
          let val = element.value;
          if (element.type === "select") {
            val = String(val);
          }
          // if(element.type === "uploadImg"){
          //   //图片数组转字符串
          //   val = JSON.stringify(element.value)
          // }
          return {
            fieldCode: element.fieldCode,
            fieldValue: val,
          };
        });
        return item;
      });

      return menuFieldRelList;
    },
    fromWatch() {
      //是否上传图片  图片是否发生变化
      //bankImg 保存上一次上传的图片
      this.fromData.forEach((item, index) => {
        let b = item[0];
        let v = this.bankImg[index];
        if (b.bankCardOcr && b.value !== v) {
          this.bankImg[index] = b.value;
          this.ocrByIdCard(b, index);
        }
      });
    },
    ocrByIdCard(val, index) {
      //银行卡认证
      if (val.value) {
        let list = [
          {
            fieldCode: val.fieldCode,
            // fieldValue:
            //   "https://dev.tranderpay.com/tranderpayfiles" + val.value,
            fieldValue: utils.getImgUrl() + val.value,
          },
        ];
        API.findOcrByBankCard([list]).then((res) => {
          if (JSON.stringify(res.data) === "{}") {
            // 没有识别出信息
            Toast({
              message: "银行卡信息识别失败",
              className: "totast-cum",
            });
            return;
          }
          this.bankObj[index] = res.data;
          this.setBankList(index);
        });
      }
    },
    setBankList(index) {
      //ocr认证信息赋值
      let data = this.bankObj[index];
      if (data) {
        this.fromData[index].forEach((item) => {
          if (item.type !== "uploadImg" && item.fieldCode !== "payee_name" && item.fieldCode !== "bank_percentage") {
            item.value = data[item.fieldCode];
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.percentSty{
  position: absolute;top: 0;right: 8px;font-weight: bold;
}
@import "@/styles/common.scss";
</style>