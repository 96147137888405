<template>
    <div class="van-cell ocr-box">
        <div class="item">
            <ocr imgType='bank' :disabled='disabled' :importType='importType' title="银行卡正面" @updateImg='updateImg' :imgSrc.sync='bankSrc'></ocr>
        </div>
        <div class="item"></div>
    </div>
</template>
<script>
import ocr from '@/components/ocr/index'
export default {
    props:{
        value:{
            type:String,
            default:''
        },
        importType:{
            type:String,
            default:''
        },
        disabled:{
            type: Boolean,
            default:false
        }
    },
    
    components: {
        ocr
    },
    data() {
        return {
            bankSrc: "", //正面图片
        }
    },
    created(){
        this.bankSrc = this.value
    },
    methods:{
        updateImg(src){
            this.$emit("update:value", src);
        }
    },
    // watch:{
    //     bankSrc : {
    //         handler(newVal){
    //             this.$emit("update:value",['https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.mp.sohu.com%2Fupload%2F20170515%2Fbb93af2cf723488cbcae2f708f204986_th.png&refer=http%3A%2F%2Fimg.mp.sohu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1623226247&t=96ca18386906ed131d09c6db6dcc6b13'])
    //         },
    //         deep:true
    //     }
    // }
}
</script>
<style lang='scss' scoped>
.ocr-box{
  width: 100%;
  display: flex;
  position: relative;
  z-index: 4;
  .item{
    flex: 1;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-right: 15px;
    &:last-child{
      padding-left: 15px;
      padding-right: 0;
    }
  }
}
</style>